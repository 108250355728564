import React, { createContext, useContext } from "react";
import { IntercomProvider } from "react-use-intercom";

import { createStore, useAtom, useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import get from "lodash/get";
import { SWRConfig } from "swr";

import {
  appConfigAtom,
  appConfigEffectAtom,
  countUnReadMessagesAtom,
  toggleLeftSideBarAtom,
} from "atoms/configurations";
import { environmentAtom } from "atoms/environment";
import { httpClientAtom } from "atoms/httpclient";
import { languageAtom } from "atoms/language";

import DetectMobileDevice from "components/detect-orientation";
import ErrorPage from "components/error-page";
import Intercom from "components/intercomm";
import { defaultLocale, useSetAppLanguage } from "components/language-wrapper";
import PageLoader from "components/loading";

import { Configuration, Environment, Session } from "types";

import { initializeAuthorizeTokenAtomEffect } from "hooks/authorize-token";
import useCheckPagePermission from "hooks/check-page-permission";
import useInitializeAppSession from "hooks/session";

import { IHttpClient } from "utils/http-client-utils";
import useInitSentryUser from "utils/sentry-utils";
import { useInitDataDogLogs } from "../utils/datadog-utils";

// DO NOT CHANGE KEYS as this would break old application authentication implementation
const appSettingsKey = "navirec-settings-";
export const authenticationKeys = {
  token: `${appSettingsKey}token`,
  accountId: `${appSettingsKey}accountId`,
  userId: `${appSettingsKey}userId`,
  language: `${appSettingsKey}language`,
  clientId: `${appSettingsKey}clientId`,
  sessionId: `${appSettingsKey}sessionId`,
  authorizeToken: `${appSettingsKey}authorize-token`,
};

export const smitMapService = process.env.NEXT_PUBLIC_SMIT_MAP_SERVICE || "";
interface ApplicationConfig {
  readonly httpClient: IHttpClient;
  readonly session?: Session;
  readonly environment?: Environment;
  readonly configuration?: Configuration | null;
  readonly collapsedSidebar: boolean;
  readonly language?: string;
  onToggleSideBar: () => void;
  setLanguage?: any;
  onMutateConfiguration?: () => void;
}

interface ApplicationProvider {
  children: any;
}

const ApplicationContext = createContext<ApplicationConfig>({
  collapsedSidebar: false,
  onToggleSideBar: () => {},
  httpClient: createStore().get(httpClientAtom),
});

const ApplicationProvider = ({ children }: ApplicationProvider) => {
  const { isLoading: isLoadingSession, session } = useInitializeAppSession();
  useAtom(appConfigEffectAtom);
  useAtom(initializeAuthorizeTokenAtomEffect);

  const environment = useAtomValue(environmentAtom);
  const httpClient = useAtomValue(httpClientAtom);
  const configuration = useAtomValue(appConfigAtom);
  const [savedLanguage, setLanguage] = useAtom(languageAtom);
  const [collapsedSidebar, setCollapsedSidebar] = useAtom(toggleLeftSideBarAtom);
  const setCountUnReadMessagesAtom = useSetAtom(countUnReadMessagesAtom);

  const error = environment?.error || configuration?.error;
  const isLoadingEnvironment = environment?.environment === undefined && environment?.error === undefined;
  const timeZone = get(configuration, "account.timezone");
  const defaultUserLanguage = get(configuration, "user.default_language");
  const defaultAccountLanguage = get(configuration, "account.default_language");
  const language = savedLanguage || defaultUserLanguage || defaultAccountLanguage || defaultLocale;

  useInitSentryUser(configuration);
  useInitDataDogLogs(configuration);
  const { isCheckingPermission } = useCheckPagePermission(configuration?.features);
  const { locale } = useSetAppLanguage(language, timeZone);

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoadingEnvironment || isLoadingSession || isCheckingPermission || configuration?.loading) {
    return <PageLoader />;
  }

  return (
    <ApplicationContext.Provider
      value={{
        configuration,
        session,
        language,
        environment: environment?.environment,
        httpClient,
        collapsedSidebar,
        onToggleSideBar: () => setCollapsedSidebar(!collapsedSidebar),
        setLanguage,
        onMutateConfiguration: configuration?.onRefresh,
      }}
    >
      <IntercomProvider
        appId={get(configuration, "services.intercom.app_id") || ""}
        autoBoot={false}
        autoBootProps={{ alignment: "right" }}
        onUnreadCountChange={(v) => {
          setCountUnReadMessagesAtom(v);
        }}
      >
        <Intercom
          configuration={configuration}
          language={locale}
          shouldLoad={!isEmpty(get(configuration, "services.intercom.app_id"))}
        >
          <SWRConfig
            value={{
              fetcher: httpClient.swrFetcher,
              shouldRetryOnError: false,
              revalidateOnFocus: false,
              revalidateOnReconnect: false,
            }}
          >
            <DetectMobileDevice>{children}</DetectMobileDevice>
          </SWRConfig>
        </Intercom>
      </IntercomProvider>
    </ApplicationContext.Provider>
  );
};

const useApplicationContext = () => useContext(ApplicationContext);

export { ApplicationProvider, useApplicationContext };
